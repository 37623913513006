<template>
  <section v-if="data" class="o-navigation-default">
    <div class="o-navigation-default__container container">
      <h2 v-if="data.title" class="o-navigation-default__title">{{ data.title }}</h2>

      <div v-for="section in data.sections" class="o-navigation-default__content">
        <h3 class="o-navigation-default__content-title">
          <a v-if="section.link" :href="section.link">
            {{ section.title }}
          </a>

          <span v-else>
            {{ section.title }}
          </span>

          <a
            v-if="section.cross"
            :href="section.cross.url"
            :title="section.cross.title"
            :aria-label="section.cross.title"
            class="o-navigation-default__link-icon"
          >
            <i class="icon-main-replica"></i>
          </a>
        </h3>

        <div class="o-navigation-default__links">
          <template v-for="group in section.groups">
            <div
              class="o-navigation-default__links-title"
              :class="{ 'o-navigation-default__links-title--part-column': !group.links.length }"
            >
              <a v-if="group.link" :href="group.link" class="link-item link-item--rounded">
                {{ group.title }}
              </a>

              <span v-else class="link-item link-item--rounded">
                {{ group.title }}
              </span>

              <a
                v-if="group.cross"
                :href="group.cross.url"
                :title="group.cross.title"
                :aria-label="group.cross.title"
                class="link-item link-item--rounded o-navigation-default__link-icon"
              >
                <i class="icon-main-replica"></i>
              </a>
            </div>

            <div v-if="group.links.length" class="o-navigation-default__links-list">
              <div v-for="link in group.links" class="o-navigation-default__link">
                <a :href="link.link" class="link-item link-item--rounded">
                  {{ link.title }}
                </a>

                <a
                  v-if="link.cross"
                  :href="link.cross.url"
                  :title="link.cross.title"
                  :aria-label="link.cross.title"
                  class="link-item link-item--rounded o-navigation-default__link-icon"
                >
                  <i class="icon-main-replica"></i>
                </a>
              </div>

              <div class="o-navigation-default__link-all">
                <Button
                  v-if="group.hub"
                  :href="group.hub.url"
                  :title="group.hub.title"
                  css-class="button-icon-text"
                  tag="a"
                  modifier="primary"
                  icon-pos="right"
                >
                  Все услуги
                  <template #icon>
                    <i class="icon-main-next"></i>
                  </template>
                </Button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TONavigationDefault } from '~/components/O/Navigation/Default/ONavigationDefault.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Navigation/Default/ONavigationDefault.schema';

defineOptions({
  name: 'ONavigationDefault',
});

const props = withDefaults(defineProps<IComponentProps<TONavigationDefault>>(), {
  viewName: 'ONavigationDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TONavigationDefault>();

const data = ref<TONavigationDefault>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
